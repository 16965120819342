function pages(state = {}, action) {
  switch (action.type) {
    case 'SET_DATA_PAGES': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export default pages;

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from "../styleApp/style";
import LogIn from "../pages/LogIn/login";
import AuthenticatedRoute from "../components/Auth/authenticatedRoute";
import Dashboard from "../pages/Dashboard/dashboard";
import Forms from "../components/Forms/forms";

const Routes = ({ dataPricingTable, refetchData, ...propsRoute }) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  const setAuth = (value) => {
    userHasAuthenticated(value);
  };
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/">
            <LogIn setAuth={setAuth} />
          </Route>
          <AuthenticatedRoute
            path="/forms"
            component={Forms}
            appProps={{ isAuthenticated, refetchData }}
          />
          <AuthenticatedRoute
            path="/dashboard"
            component={Dashboard}
            appProps={{ isAuthenticated, refetchData }}
          />
        </Switch>
      </Layout>
    </Router>
  );
};

export default Routes;

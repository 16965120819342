import React, { useState } from "react";
import { ListMessageTxtWrapper } from "./style";
import { withRouter } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import { Modal, ModalBody } from "reactstrap";
import FormModal from "../FormModal/formModal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const ListMessageTxt = (props) => {
  const [rowActive, setRowActive] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    props.refresh();
  };

  const changeRowActive = (row) => {
    setRowActive(row);
    setModal(true);
  };

  const editFormatter = (cell, row) => {
    return (
      <a
        className="go-to"
        title="editar"
        onClick={() => {
          changeRowActive(row);
        }}
      >
        <EditIcon />
      </a>
    );
  };
  const estadoFormatter = (cell, row) => {
    return row.activo == 1 ? "Activo" : "Inactivo";
  };
  const columns = [
    {
      dataField: "id",
      text: "Mensaje ID",
      headerStyle: (colum, colIndex) => {
        return { width: "10%", textAlign: "center" };
      },
    },
    {
      dataField: "mensaje",
      text: "Mensaje",
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "left" };
      },
    },
    {
      dataField: "agente",
      text: "Agente",
    },
    {
      dataField: "fecha_creacion",
      text: "Fecha creación",
    },
    {
      dataField: "activo",
      text: "Estado",
      formatter: estadoFormatter,
    },
    {
      dataField: "accion",
      text: "Estado",
      formatter: editFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "10%", textAlign: "rigth" };
      },
    },
  ];

  return (
    <ListMessageTxtWrapper>
      <div className="table-container">
        <BootstrapTable
          keyField="id"
          data={props.listMessageTxt}
          columns={columns}
          pagination={paginationFactory()}
        />
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        className="modal-lg "
      >
        <ModalBody>
          <FormModal message={rowActive} toggle={toggle} />
        </ModalBody>
      </Modal>
    </ListMessageTxtWrapper>
  );
};

export default withRouter(ListMessageTxt);

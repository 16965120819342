export const initialState = {
  formData: {
    finalProcess: false,
  },
  pages: {
    sections: [],
    metas: [],
    bubbleNotification: 0,
  },
  externalFormData: [],
  animation: {
    type: "next",
  },
};

export const updateData = payload => ({
  type: 'UPDATE_DATA',
  payload,
});


export const resetData = () => ({
  type: 'RESET_DATA',
  payload: {
  }
})


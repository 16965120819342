import styled from "styled-components";
import { black } from "../../StylesConstants";

export const ListMessageTxtWrapper = styled.div`
  .table-container {
    grid-area: content;
    margin: 15px;
  }

  .update {
    button {
      background: none;
      border: none;
      width: 100%;
    }
  }

  .go-to {
    color: ${black};
  }

  .paginator-container {
    grid-area: paginator;
  }

  @media screen and (max-width: 767px) {
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: #ffffff;
    }
    .table td,
    .table th {
      border-top: none;
    }
  }
`;

import styled from "styled-components";

export const LoginWrapper = styled.section`
  position: relative;
  display: grid;
  justify-items: center;
  width: 100vw;
  height: 100vh;
  .login-area {
    display: grid;
    position: absolute;
    border: 1px solid #dadce0;
    width: 600px;
    height: 400px;
    border-radius: 30px;
    top: 15%;
    align-items: center;
    justify-items: center;
    img {
      width: 60px;
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { FormWrapper, ZoneInput, Subtitle } from "./style";
import TextArea from "@habi/habi-react-components/dist/TextArea/textArea";
import TextInput from "@habi/habi-react-components/dist/TextInput/textInput";
import Checkbox from "@habi/habi-react-components/dist/Checkbox/checkbox";
import Button from "@habi/habi-react-components/dist/Button/button";
import Axios from "axios";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const FormModal = ({ message, ...props }) => {
  const [initialValues, setInitialValues] = useState({
    mensaje: "",
    active: false,
    agente: "",
  });
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    let newValues = {};
    if (message) {
      newValues["fecha_creacion"] = message.fecha_creacion;
      newValues["fecha_actualizacion"] = message.fecha_actualizacion;
      newValues["agente"] = message.agente;
      newValues["mensaje"] = message.mensaje;
      newValues["active"] = message.activo;
    }
    setInitialValues(newValues);
  }, [message]);

  const handleSave = (values) => {
    const body = {
      id: message.id,
      agente: props.email,
      mensaje: values.mensaje,
      activo: values.active ? 1 : 0,
    };
    const apiKey = process.env.REACT_APP_HABI_API_MARKETING_KEY;
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_HABI_API_MARKETING_BASE_PATH;
    const endpointUrl =
      process.env.REACT_APP_ENDPOINT_HABI_API_MARKETING_PUT_MESSAGE;
    const url = rootUrl + endpointUrl;

    const fetchData = async () => {
      setIsloading(true);
      await Axios({
        url: url,
        method: "put",
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(body),
      })
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsloading(false);
          props.history.push({
            pathname: "/dashboard",
            state: {},
          });
          props.toggle();
        });
    };
    fetchData();
  };

  const EditionSchema = Yup.object().shape({
    mensaje: Yup.string()
      .min(3, "Por favor ingrese un mensaje valido minimo 4 caracteres")
      .max(250, "Por favor ingrese un mensaje valido")
      .required("El mensaje es requerido, por favor ingrese uno."),
  });
  return (
    <FormWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={EditionSchema}
        onSubmit={(values, errors) => {
          handleSave(values, errors);
        }}
      >
        {(propsForm) => (
          <Form>
            <div>
              <div className="header-form">
                <h1 className="main-title">Mensaje #{message.id}</h1>
              </div>
              <div className="body-form">
                <ZoneInput>
                  <Subtitle>
                    <p>Editar mensaje de texto</p>
                  </Subtitle>
                </ZoneInput>
                <ZoneInput>
                  <Subtitle>
                    <p>Fecha de creación</p>
                  </Subtitle>

                  <TextInput
                    name="fecha_creacion"
                    value={propsForm.values["fecha_creacion"]}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    id="fecha_creacion"
                    type="text"
                    values={propsForm.values}
                    placeholder=""
                    disabled
                  />
                </ZoneInput>
                <ZoneInput>
                  <Subtitle>
                    <p>Fecha de ultima Modificación</p>
                  </Subtitle>
                  <TextInput
                    name="fecha_actualizacion"
                    value={propsForm.values["fecha_actualizacion"]}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    id="fecha_actualizacion"
                    type="text"
                    values={propsForm.values}
                    placeholder=""
                    disabled
                  />
                </ZoneInput>
                <ZoneInput>
                  <Subtitle>
                    <p>Agente</p>
                  </Subtitle>
                  <TextInput
                    name="agente"
                    value={propsForm.values["agente"]}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    id="agente"
                    type="text"
                    values={propsForm.values}
                    placeholder=""
                    disabled
                  />
                </ZoneInput>
                <ZoneInput>
                  <Subtitle>
                    <p>Mensaje</p>
                  </Subtitle>
                  <TextArea
                    name="mensaje"
                    value={propsForm.values["mensaje"]}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    id="mensaje"
                    type="textarea"
                    placeholder={"..."}
                    className="input-description"
                    values={propsForm.values}
                  />
                </ZoneInput>
                <ZoneInput>
                  <Subtitle>
                    <p>Estado</p>
                  </Subtitle>
                  <Checkbox
                    id="active"
                    name="active"
                    value={propsForm.values["is_active"]}
                    values={propsForm.values}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    label="Activo"
                  />
                </ZoneInput>
              </div>
              <Button
                id="guardar"
                typeClass="primary"
                type="submit"
                className="d-inline-block"
                label="Guardar"
              ></Button>
            </div>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
const mapStateToProps = (state) => ({
  role: state.formData.role,
  name: state.formData.name,
  lastName: state.formData.lastName,
  email: state.formData.email,
  imageProfile: state.formData.imageProfile,
});
export default withRouter(connect(mapStateToProps, null)(FormModal));

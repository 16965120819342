import React from "react";
import Header from "../../components/Header/header";
import { DashBoardWrapper } from "./style";
import ListMessageTxtContainer from "../../components/ListMarketingTxt/listMarketingTxtContainer";
import Button from "@habi/habi-react-components/dist/Button/button";

const Dashboard = (props) => {
  const goToForm = () => {
    props.history.push({
      pathname: "/forms",
      state: {
        update: false,
      },
    });
  };
  return (
    <DashBoardWrapper>
      <Header className="header" />
      <div className="body">
        <div className="button">
          <Button
            id="Create"
            typeClass="primary"
            type="submit"
            className="d-inline-block"
            label="Nuevo mensaje"
            onClick={goToForm}
          ></Button>
        </div>
        <ListMessageTxtContainer />
      </div>
    </DashBoardWrapper>
  );
};

export default Dashboard;

import styled from "styled-components";
import { white, grey, scarpaGray700, archiaRegular } from "../../StylesConstants";

export const HeaderWrapper = styled.div`
  position: relative;
  height: 80px;
  display: grid;
  grid-template: 1fr/1fr 1fr;
  background-color: ${white};
  font-size: 14px;
  padding: 0px 24px;
  border-bottom: 1px solid ${grey};
  
  .items-container {
    display: flex;
    align-items: center;
    .logo {
      height: 43px;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .menu-title {
      font-family: ${archiaRegular};
      color: ${scarpaGray700};
      font-size: 16px;
      margin: 0px 7px;
    }
    .menu-icon {
      color: ${scarpaGray700};
    }
  }
`;
import { createStore, combineReducers } from 'redux';
import steps from './reducers/steps';
import formData from './reducers/formData';
import externalFormData from './reducers/externalFormData';
import pages from './reducers/pages';
import animation from './reducers/animation';
import { initialState } from './storeConst';

const reducers = combineReducers({
  steps,
  formData,
  pages,
  externalFormData,
  animation,
});

const store = createStore(
  reducers,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;

import styled from "styled-components";

export const DashBoardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "body";

  .header {
    grid-area: header;
  }

  .body {
    grid-area: body;
    padding: 0px 24px;
  }
  .button {
    display: grid;
    justify-content: flex-end;
    margin: 10px 0px;
  }
`;

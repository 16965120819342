import React from "react";
import logo from "../../static/assets/logo/habi.svg";
import { HeaderWrapper } from "./style";

const Header = (props) => {
  return (
    <HeaderWrapper>
      <div className="items-container">
        <img src={logo} alt="Habi logo" className="logo"></img>
      </div>
      <div className="menu"></div>
    </HeaderWrapper>
  );
};

export default Header;

import React, { useState } from "react";
import { withRouter } from "react-router";
import { Formik, Form } from "formik";
import Header from "../Header/header";
import { FormWrapper, ZoneInput, Subtitle } from "./style";
import TextArea from "@habi/habi-react-components/dist/TextArea/textArea";
import Checkbox from "@habi/habi-react-components/dist/Checkbox/checkbox";
import Button from "@habi/habi-react-components/dist/Button/button";
import Axios from "axios";
import { connect } from "react-redux";
import * as Yup from "yup";

const Forms = ({ message, ...props }) => {
  const [isLoading, setIsloading] = useState(false);

  const handleSave = (values, errors) => {
    const body = {
      agente: props.email,
      mensaje: values.mensaje,
      activo: values.active ? 1 : 0,
    };
    const apiKey = process.env.REACT_APP_HABI_API_MARKETING_KEY;
    const rootUrl =
      process.env.REACT_APP_FORM_API_URL_V2 +
      process.env.REACT_APP_ENDPOINT_HABI_API_MARKETING_BASE_PATH;
    const endpointUrl =
      process.env.REACT_APP_ENDPOINT_HABI_API_MARKETING_POST_MESSAGE;
    const url = rootUrl + endpointUrl;

    const fetchData = async () => {
      setIsloading(true);
      await Axios({
        url: url,
        method: "post",
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(body),
      })
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsloading(false);
          props.history.push({
            pathname: "/dashboard",
          });
        });
    };
    if (Object.keys(errors).length !== 0) {
      fetchData();
    }
  };

  const CreateSchema = Yup.object().shape({
    mensaje: Yup.string()
      .min(3, "Por favor ingrese un mensaje valido minimo 4 caracteres")
      .max(250, "Por favor ingrese un mensaje valido")
      .required("El mensaje es requerido, por favor ingrese uno."),
  });

  return (
    <FormWrapper>
      {!message && <Header className="header" />}
      <Formik
        enableReinitialize={true}
        validationSchema={CreateSchema}
        initialValues={{ mensaje: "", active: false }}
        onSubmit={(values, errors) => {
          handleSave(values, errors);
        }}
      >
        {(propsForm) => (
          <Form>
            <div className="container-habi">
              <div className="header-form">
                <h1 className="main-title">Creación de un Mensaje TXT</h1>
              </div>
              <div className="body-form">
                <ZoneInput>
                  <Subtitle>
                    <p>Mensaje de texto</p>
                  </Subtitle>
                </ZoneInput>
                <ZoneInput>
                  <TextArea
                    name="mensaje"
                    value={propsForm.values["mensaje"]}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    id="mensaje"
                    type="textarea"
                    placeholder={
                      "Elige el mensaje indicado para lo que quieres decir"
                    }
                    className="input-description"
                    values={propsForm.values}
                  />
                </ZoneInput>
                <ZoneInput>
                  <Subtitle>
                    <p>Estado</p>
                  </Subtitle>
                  <Checkbox
                    id="active"
                    name="active"
                    value={propsForm.values["active"]}
                    values={propsForm.values}
                    errors={propsForm.errors}
                    touched={propsForm.touched}
                    label="Activo"
                  />
                </ZoneInput>
              </div>
              {!isLoading && (
                <Button
                  id="guardar"
                  typeClass="primary"
                  type="submit"
                  className="d-inline-block"
                  label="Guardar"
                  onClick={() => {
                    handleSave(propsForm.values, propsForm.errors);
                  }}
                ></Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  role: state.formData.role,
  name: state.formData.name,
  lastName: state.formData.lastName,
  email: state.formData.email,
  imageProfile: state.formData.imageProfile,
});
export default withRouter(connect(mapStateToProps, null)(Forms));

import styled from "styled-components";
import { white, size } from "../StylesConstants";

export const Layout = styled.div`
  body {
    background-color: ${white};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .react-router-modal__modal {
    width: 100%;
    height: 105vh;
    overflow: scroll;
    @media (min-width: 360px) {
      top: 21rem;
    }
    @media (min-width: 360px) {
      top: 21rem;
    }
    @media (max-width: 375px) and (min-width: 373px) {
      top: 26rem;
    }
    @media (min-width: 410px) {
      top: 24rem;
    }
    @media (min-width: ${size.tablet}) {
      width: 80%;
      height: 100vh;
      overflow: scroll;
      top: 500px;
    }
    @media (min-width: ${size.desktop}) {
      width: 80%;
      height: 100vh;
      overflow: scroll;
      top: 50%;
    }
  }
  .react-router-modal__container {
    z-index: 1;
  }

  .loader-custom {
    height: 100%;
    width: 100%;
    margin: 15% 0%;
    text-align: center;
  }

  .react-router-modal__modal--in {
    overflow: hidden;
    height: 98%;
  }
`;

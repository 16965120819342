import { initialState as instate } from '../storeConst';

function formData(state = {}, action) {
  switch (action.type) {
    case 'UPDATE_DATA': {
      return { ...state, ...action.payload };
    }
    case 'RESET_DATA': {
      let newstate = instate.formData;
      newstate.status = state.status;
      let checkNewState = { ...newstate };
      return checkNewState;
    }
    default:
      return state;
  }
}

export default formData;

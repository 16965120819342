import React, { useEffect, useState } from "react";
import axios from "axios";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { connect } from "react-redux";
import ListMessageTxt from "./listMarketingTxt";

const ListMessageTxtContainer = (props) => {
  const rootUrl =
    process.env.REACT_APP_FORM_API_URL_V2 +
    process.env.REACT_APP_ENDPOINT_HABI_API_MARKETING_BASE_PATH;
  const endpoint =
    process.env.REACT_APP_ENDPOINT_HABI_API_MARKETING_GET_LIST_MESSAGE;
  const urlGetList = rootUrl + endpoint;
  const apiKey = process.env.REACT_APP_HABI_API_MARKETING_KEY;
  //States
  const [listMessageTxt, setListMessageTxt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    let data = await axios.get(urlGetList, {
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json",
      },
    });
    setListMessageTxt(data.data.data);
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);

    fetchData();
  }, []);

  return (
    <BlockUi tag="div" blocking={isLoading}>
      <ListMessageTxt listMessageTxt={listMessageTxt} refresh={fetchData} />
    </BlockUi>
  );
};

const mapStateToProps = (state) => ({
  role: state.formData.role,
});

export default connect(mapStateToProps)(ListMessageTxtContainer);

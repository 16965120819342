import styled from "styled-components";
import {
  brightTurquoise600,
  scarpaGray500,
  scarpaGray700,
  size,
} from "../../StylesConstants";

export const FormWrapper = styled.div`
  @media (min-width: ${size.tablet}) {
  }
  @media (min-width: ${size.desktop}) {
    .header-form {
      padding: 40px 0px;
    }
    #guardar-container {
      width: 100%;
      text-align: right;
      justify-content: right;
      display: grid !important;
    }
  }
`;
export const ZoneInput = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: ${scarpaGray500};
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  color: ${scarpaGray700};
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: 80% 20%;

  p {
    margin: 0 !important;
  }
  .indicator-chars {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: ${brightTurquoise600};
  }
`;

import React, { Fragment } from "react";
import { LoginWrapper } from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateData } from "../../redux/actions/formData";
import logo from "../../static/assets/logo/habi.svg";
import GoogleLogin from "react-google-login";

const LogIn = (props) => {
  const onRejectLogin = (response) => {
    props.history.push("/");
  };
  const onSuccessLogin = (response) => {
    const profile = response.profileObj;
    if (profile.googleId) {
      const data = {
        userId: profile.googleId,
      };
      const rootUrl =
        process.env.REACT_APP_FORM_API_URL_V2 +
        process.env.REACT_APP_ENDPOINT_HABI_API_BASE_PATH;
      const endpointUrl =
        process.env.REACT_APP_ENDPOINT_HABI_API_GET_COGNITO_USER_ROLE;
      const url = rootUrl + endpointUrl;
      fetch(url, {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_HABI_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success && response.role === "marketing") {
            props.updateData({
              role: response.role,
              email: profile.email,
              name: profile.givenName,
              lastName: profile.familyName,
              imageProfile: profile.imageUrl,
              authenticated: true,
            });
            props.setAuth(true);
            props.history.push("/dashboard");
          }
        });
    }
  };

  return (
    <Fragment>
      <LoginWrapper>
        <section className="login-area">
          <div className="logo-area">
            <img src={logo} alt="logo-img" />
          </div>
          <div className="pass-button">
            <GoogleLogin
              clientId="1050153132365-2ucv8d21tdc2hotlbtesmup12lfki83t.apps.googleusercontent.com"
              buttonText="Iniciar Sesión"
              onSuccess={onSuccessLogin}
              onFailure={onRejectLogin}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </section>
      </LoginWrapper>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  role: state.formData.role,
  name: state.formData.name,
  lastName: state.formData.lastName,
  email: state.formData.email,
  imageProfile: state.formData.imageProfile,
});
const mapDispatchToProps = (dispatch) => ({
  updateData: (data) => dispatch(updateData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
